import { useSessionContext } from "supertokens-auth-react/recipe/session";

export default function useIsLoggedIn() {
  const sessionContext = useSessionContext();
  let isLoggedIn;
  if (sessionContext.loading) {
    isLoggedIn = false;
  } else {
    isLoggedIn = sessionContext.doesSessionExist;
  }
  return isLoggedIn;
}
