import { createContext, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";

import * as jsonpatch from "fast-json-patch";

import SuperTokens, { SuperTokensWrapper } from "supertokens-auth-react";
import EmailPassword from "supertokens-auth-react/recipe/emailpassword";
import Session, { SessionAuth } from "supertokens-auth-react/recipe/session";
import { getSuperTokensRoutesForReactRouterDom } from "supertokens-auth-react/ui";
import { EmailPasswordPreBuiltUI } from "supertokens-auth-react/recipe/emailpassword/prebuiltui";
import * as reactRouterDom from "react-router-dom";

import CookieNotice from "./components/CookieNotice";
import Edit from "./pages/Edit";
import Home from "./pages/Home";
import Impressum from "./pages/Impressum";
import News from "./pages/News";

import "@fontsource/roboto";
import "@fontsource/roboto/100.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Content } from "./util/contentTypes";

const API_DOMAIN = process.env.REACT_APP_API_DOMAIN || "";
const WEBSITE_DOMAIN = process.env.REACT_APP_WEBSITE_DOMAIN || "";

SuperTokens.init({
  appInfo: {
    appName: "app",
    apiDomain: API_DOMAIN,
    websiteDomain: WEBSITE_DOMAIN,
    apiBasePath: "/auth",
    websiteBasePath: "/auth",
  },
  recipeList: [
    EmailPassword.init({
      signInAndUpFeature: {
        signInForm: {
          style: `
                [data-supertokens~=headerSubtitle] {
                    display: none;
                }
            `,
        },
      },
    }),
    Session.init(),
  ],
});

export const GlobalContext = createContext<any>(null);

function App() {
  const [content, setContent] = useState<Content>();
  const [beforeUpdateContent, setBeforeUpdateContent] = useState<Content>();
  const [cookiesAccepted, setCookiesAccepted] = useState(
    document.cookie.includes("acceptedCookies=")
  );

  function handleAcceptCookies() {
    setCookiesAccepted(true);
    const d = new Date();
    d.setTime(d.getTime() + 100 * 365 * 24 * 60 * 60 * 1000);
    document.cookie =
      "acceptedCookies=TRUE;expires=" +
      d.toUTCString() +
      ";SameSite=Lax; " +
      document.cookie;
  }

  const autoSaveTimout = 500;
  useEffect(() => {
    if (beforeUpdateContent === undefined || content === undefined) return;

    const timeoutId = setTimeout(() => {
      const diff = jsonpatch.compare(beforeUpdateContent, content);
      if (diff.length === 0) return;

      fetch(
        API_DOMAIN +
          "/update-content?diff=" +
          encodeURIComponent(JSON.stringify(diff)),
        {
          method: "PATCH",
        }
      )
        .then((response) => response.json())
        .catch((error) => console.error("Error fetching data:", error));

      setBeforeUpdateContent(content);
    }, autoSaveTimout);
    return () => clearTimeout(timeoutId);

    // do not update on beforeUpdateContent change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  useEffect(() => {
    fetch(API_DOMAIN + "/content")
      .then((response) => response.json())
      .then((data) => {
        setContent(data as Content);
        setBeforeUpdateContent(data as Content);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  return (
    <SuperTokensWrapper>
      <GlobalContext.Provider
        value={{
          content,
          setContent,
          API_DOMAIN,
          WEBSITE_DOMAIN,
          cookiesAccepted,
          handleAcceptCookies,
        }}
      >
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/news" element={<News />} />
            <Route path="/impressum" element={<Impressum />} />
            {/*This renders the login UI on the /auth route*/}
            {getSuperTokensRoutesForReactRouterDom(reactRouterDom, [
              EmailPasswordPreBuiltUI,
            ])}
            <Route
              path="/edit"
              element={
                <SessionAuth>
                  <Edit />
                </SessionAuth>
              }
            />
            <Route path="/downloads" element={<Navigate to="/#downloads" />} />
            <Route path="/services" element={<Navigate to="/#services" />} />
            <Route path="*" element={<Navigate to="/#" />} />
          </Routes>
          <CookieNotice
            modal
            description="Um die Webseite richtig darzustellen verwenden wir Cookies, so wie Elemente von Drittanbietern. Wenn Sie dem nicht zustimmen, können manche Inhalte nicht geladen werden. Im Impressum befinden sich weitere Informationen."
            accept="Akzeptieren"
            reject="Nicht notwendige Cookies und Inhalte ablehnen"
          />
        </Router>
      </GlobalContext.Provider>
    </SuperTokensWrapper>
  );
}

export default App;
