import CSS from "csstype";
import { Image } from "react-bootstrap";
import { HashLink as Link } from "react-router-hash-link";

import useGlobalContext from "../hooks/useGlobalContext";

interface Props {
  src_mobile: string;
  src_landscape: string;
  link: string;
  alt?: string;
}

function JobPosting({ src_mobile, src_landscape, alt, link }: Props) {
  const { isWidescreen } = useGlobalContext();
  let src: string;

  if (!isWidescreen) src = src_mobile;
  else src = src_landscape;

  return (
    <Link to={link}>
      <Image src={src} alt={alt} className={"p-3 w-100"} />
    </Link>
  );
}

export default JobPosting;
