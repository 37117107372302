import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";

import copy from "clipboard-copy";

import useGlobalContext from "../hooks/useGlobalContext";

function CreateUser() {
  const { API_DOMAIN } = useGlobalContext();
  const [link, setLink] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");

  async function handleCreateUser(event: React.FormEvent) {
    event.preventDefault();
    setLink("");

    const response = await fetch(
      API_DOMAIN + `/create-user?email=${encodeURIComponent(email)}`,
      {
        method: "POST",
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      setError(errorData.detail);
      return;
    }

    const data = await response.json();
    setLink(data.link);
  }

  return (
    <Form onSubmit={handleCreateUser} onChange={() => setError("")}>
      {error && (
        <Form.Group className="mb-3">
          <Form.Label className="text-danger">
            <b>Error: {error}</b>
          </Form.Label>
        </Form.Group>
      )}
      <Form.Group className="mb-3" controlId="formEmail">
        <Form.Label>Email-Adresse:</Form.Label>
        <Form.Control
          required
          type="email"
          placeholder="Email-Adresse"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
        />
      </Form.Group>
      <Button variant="primary" type="submit">
        Erstellen
      </Button>
      {link && (
        <Button className="ms-3" variant="success" onClick={() => copy(link)}>
          Kopiere Zurücksetzungslink
        </Button>
      )}
    </Form>
  );
}

export default CreateUser;
