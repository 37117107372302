import Footer from "../components/Footer";
import Header from "../components/Header";
import SectionNews from "../components/sections/SectionNews";

import useGlobalContext from "../hooks/useGlobalContext";
import { Content } from "../util/contentTypes";

function News() {
  const { content }: { content: Content } = useGlobalContext();

  const activeNews = content
    ? content.news.filter((news) => news.visible === "true")
    : [];

  return (
    <>
      <Header />
      <main className="d-flex flex-column align-items-center flex-fill">
        {content &&
          activeNews.map((news, index) => {
            return (
              <SectionNews
                key={index}
                shortDescription={news.shortDescription || ""}
                duration={news.duration}
                title={news.title || ""}
                description={news.description}
              />
            );
          })}
      </main>
      <Footer />
    </>
  );
}

export default News;
