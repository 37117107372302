import {
  AccessibilityHelp,
  Alignment,
  Autoformat,
  AutoLink,
  Autosave,
  BlockQuote,
  Bold,
  Essentials,
  GeneralHtmlSupport,
  Heading,
  Indent,
  IndentBlock,
  //   Italic,
  Link,
  List,
  ListProperties,
  MediaEmbed,
  Paragraph,
  PasteFromOffice,
  RemoveFormat,
  SelectAll,
  SourceEditing,
  Strikethrough,
  Subscript,
  Superscript,
  TextTransformation,
  Underline,
  Undo,
} from "ckeditor5";

import { AlignmentConfig } from "@ckeditor/ckeditor5-alignment/src/alignmentconfig";
import { GeneralHtmlSupportConfig } from "@ckeditor/ckeditor5-html-support/src/generalhtmlsupportconfig";

const alignmentConfig = {
  options: [
    {
      name: "left",
      className: "text-start", // seems to be ignored / not applied, but should still have the same result
    },
    {
      name: "center",
      className: "text-center",
    },
    {
      name: "right",
      className: "text-end",
    },
  ],
} as AlignmentConfig;

const htmlSupportConfig = {
  allow: [
    {
      name: "i",
      classes: true,
    },
  ],
} as GeneralHtmlSupportConfig;

export const editorConfig = {
  alignment: alignmentConfig,
  htmlSupport: htmlSupportConfig,
  toolbar: {
    items: [
      "heading",
      "|",
      "bold",
      "underline",
      "strikethrough",
      "subscript",
      "superscript",
      "|",
      "bulletedList",
      "numberedList",
      "blockQuote",
      "link",
      "removeFormat",
      "|",
      "indent",
      "outdent",
      "|",
      "alignment",
      "|",
      "undo",
      "redo",
      "sourceEditing",
    ],
    shouldNotGroupWhenFull: false,
  },
  plugins: [
    AccessibilityHelp,
    Alignment,
    Autoformat,
    AutoLink,
    Autosave,
    BlockQuote,
    Bold,
    Essentials,
    GeneralHtmlSupport,
    Heading,
    Indent,
    IndentBlock,
    // Italic,
    Link,
    List,
    ListProperties,
    MediaEmbed,
    Paragraph,
    PasteFromOffice,
    RemoveFormat,
    SelectAll,
    SourceEditing,
    Strikethrough,
    Subscript,
    Superscript,
    TextTransformation,
    Underline,
    Undo,
  ],
  //   language: { ui: "de" },
  placeholder: "Type or paste your content here!",
};
