import { Accordion, Card, useAccordionButton } from "react-bootstrap";

import { produce } from "immer";

import {
  faArrowDown,
  faArrowUp,
  faCopy,
  faPenToSquare,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";

import ButtonWithIcon, { ButtonWithIconProps } from "./ButtonWithIcon";
import Editor from "./Editor";

import {
  Download,
  News,
  Person,
  Section,
  Service,
  HoursEntry,
  SectionElement,
} from "../util/contentTypes";
import { capitalize, getNWords } from "../util/textTools";

interface EditToggleProps extends ButtonWithIconProps {
  eventKey: string;
}

function EditToggle({ eventKey, ...otherProps }: EditToggleProps) {
  const decoratedOnClick = useAccordionButton(eventKey);

  return <ButtonWithIcon onClick={decoratedOnClick} {...otherProps} />;
}

interface Props {
  list:
    | Section[]
    | News[]
    | Person[]
    | Service[]
    | Download[]
    | HoursEntry[]
    | SectionElement[];
  setList: (
    list:
      | Section[]
      | News[]
      | Person[]
      | Service[]
      | Download[]
      | HoursEntry[]
      | SectionElement[]
  ) => void;
}

function AccordionListEditor({ list, setList }: Props) {
  const numberOfWordsShownOnPreview = 6;
  return (
    <Accordion>
      {list &&
        list.map((entry: any, index: number) => {
          let label;
          if ("title" in entry) {
            label = entry.title;
          } else if ("name" in entry) {
            label = entry.name;
          } else if ("label" in entry && "value" in entry) {
            label = entry.label + ": " + entry.value;
          } else if ("type" in entry && entry.type === "textblock") {
            label =
              "Text - " + getNWords(entry.value!, numberOfWordsShownOnPreview);
          } else if ("type" in entry && entry.type === "imageLandscape") {
            label = "Image - " + entry.src!;
          } else if ("type" in entry && entry.type === "foldedElements") {
            label =
              "Folded Elements - " +
              getNWords(
                entry.elementsFolded[0].value!,
                numberOfWordsShownOnPreview
              );
          } else if ("type" in entry) {
            label = "Special Element - " + String(capitalize(entry.type));
          } else {
            label = "no label found";
          }
          return (
            <Card key={index}>
              <Card.Header>
                <ButtonWithIcon
                  icon={faArrowUp}
                  variant=""
                  className="me-1"
                  title="Move up"
                  style={{
                    visibility: index === 0 ? "hidden" : "visible",
                  }}
                  onClick={() => {
                    if (index !== 0) {
                      setList(
                        produce(list, (draft) => {
                          [draft[index], draft[index - 1]] = [
                            draft[index - 1],
                            draft[index],
                          ];
                        })
                      );
                    }
                  }}
                />
                <ButtonWithIcon
                  icon={faArrowDown}
                  variant=""
                  className={"me-1"}
                  style={{
                    visibility:
                      index === list.length - 1 ? "hidden" : "visible",
                  }}
                  title="Move down"
                  onClick={() => {
                    if (index !== list.length - 1) {
                      setList(
                        produce(list, (draft) => {
                          [draft[index], draft[index + 1]] = [
                            draft[index + 1],
                            draft[index],
                          ];
                        })
                      );
                    }
                  }}
                />
                <EditToggle
                  eventKey={String(index)}
                  icon={faPenToSquare}
                  className="me-1"
                  title="Edit"
                />
                <ButtonWithIcon
                  icon={faCopy}
                  variant="secondary"
                  className="me-1"
                  title="Copy"
                  onClick={() =>
                    setList(
                      produce(list, (draft) => {
                        draft.splice(index, 0, entry);
                      })
                    )
                  }
                />
                <ButtonWithIcon
                  icon={faTrash}
                  variant="danger"
                  className="me-1"
                  title="Delete"
                  onClick={() =>
                    setList(
                      produce(list, (draft) => {
                        draft.splice(index, 1);
                      })
                    )
                  }
                />
                <h4 className="d-inline align-middle ms-3 text-nowrap">
                  <b>{label}</b>
                </h4>
              </Card.Header>
              <Accordion.Collapse eventKey={String(index)}>
                <Card.Body>
                  <Editor
                    data={entry}
                    setData={(data: any) =>
                      setList(
                        produce(list, (draft) => {
                          draft[index] = data;
                        })
                      )
                    }
                  />
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          );
        })}
    </Accordion>
  );
}

export default AccordionListEditor;
