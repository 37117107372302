import { useState } from "react";
import { Card } from "react-bootstrap";

import { HashLink as Link } from "react-router-hash-link";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";

interface Props {
  title: string;
  description: string;
  src: string;
}

function DownloadsCard({ title, description, src }: Props) {
  const [isHovered, setIsHovered] = useState(false);

  const shadowStyle = isHovered
    ? "rgba(var(--bs-primary-rgb), 0.8) 0 2px 16px 0"
    : "none";

  return (
    <Card
      onMouseOver={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      style={{
        boxShadow: shadowStyle,
        maxWidth: "500px",
      }}
      className="w-100 mb-3"
    >
      <Link
        to={src}
        target="_blank"
        rel="noopener noreferrer"
        className="text-decoration-none text-reset"
      >
        <Card.Body className="position-relative">
          <Card.Title className={description ? "" : "mb-0"}>{title}</Card.Title>
          {description && <Card.Text>{description}</Card.Text>}
          <FontAwesomeIcon
            icon={faFilePdf}
            className="position-absolute top-0 start-0 translate-middle fs-2"
            style={{
              color: "#c30c02",
            }}
          />
        </Card.Body>
      </Link>
    </Card>
  );
}

export default DownloadsCard;
