import { Button, ButtonProps } from "react-bootstrap";

import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export interface ButtonWithIconProps extends ButtonProps {
  icon: IconProp;
  iconProps?: FontAwesomeIconProps;
}

function ButtonWithIcon({
  icon,
  iconProps,
  ...buttonProps
}: ButtonWithIconProps) {
  return (
    <Button {...buttonProps}>
      <FontAwesomeIcon icon={icon} {...iconProps} />
    </Button>
  );
}

export default ButtonWithIcon;
