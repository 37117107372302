import { useState } from "react";

import { hyphenateSync as hyphenate } from "hyphen/de";

interface Props {
  title: string;
  subtitle: string;
  imgSrc: string;
  onClick: () => void;
}

function ServiceCard({ title, subtitle, imgSrc, onClick }: Props) {
  const [isHovered, setIsHovered] = useState(false);

  function handleMouseOver() {
    setIsHovered(true);
  }
  function handleMouseLeave() {
    setIsHovered(false);
  }

  return (
    <div
      className="card text-bg-dark border-0"
      onMouseOver={handleMouseOver}
      onMouseLeave={handleMouseLeave}
      onClick={onClick}
    >
      <img
        src={imgSrc}
        className="card-img"
        alt="..."
        style={{ opacity: 0.65 }}
      />
      <div
        className="card-img-overlay border border-2 border-primary p-2 p-sm-3 d-flex flex-column justify-content-center text-center d-md-block text-md-start"
        style={{
          textShadow:
            "0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black, 0 0 5px black",
          boxShadow: isHovered
            ? "rgba(var(--bs-primary-rgb), 0.8) 0 2px 16px 0"
            : "none",
          cursor: "pointer",
        }}
      >
        {title && <h5 className="card-title">{hyphenate(title)}</h5>}
        {subtitle && (
          <p className="card-text d-none d-md-block">{hyphenate(subtitle)}</p>
        )}
      </div>
    </div>
  );
}

export default ServiceCard;
