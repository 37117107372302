import parse, { HTMLReactParserOptions } from "html-react-parser";

interface Props {
  html: string;
  parserOptions?: HTMLReactParserOptions;
}

function ParsedHTML({ html, parserOptions }: Props) {
  return <>{parse(html || "", parserOptions)}</>;
}

export default ParsedHTML;
