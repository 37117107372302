import CSS from "csstype";
import { Image } from "react-bootstrap";

import useGlobalContext from "../hooks/useGlobalContext";

interface Props {
  src: string;
  alt?: string;
  align?: CSS.Property.Float;
}

function ImageLandscape({ src, alt, align }: Props) {
  const { isWidescreen } = useGlobalContext();

  if (!isWidescreen) return <></>;

  const style = {
    width: "min(46%, 600px)",
  };

  let float = null;
  if (align === "left") float = "float-start";
  if (align === "right") float = "float-end";

  return <Image src={src} alt={alt} style={style} className={"p-3 " + float} />;
}

export default ImageLandscape;
