import useGlobalContext from "../hooks/useGlobalContext";

interface Props {
  src: string;
}

function ImageParallax({ src }: Props) {
  const { isWidescreen } = useGlobalContext();

  if (isWidescreen) return <></>;

  const style = {
    backgroundAttachment: "fixed",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundImage: "url(" + src + ")",
    height: "min(40vh, 300px)",
  };

  return <div style={style} />;
}

export default ImageParallax;
