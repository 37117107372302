import { HashLink as Link } from "react-router-hash-link";

import useGlobalContext from "../hooks/useGlobalContext";
import { Content } from "../util/contentTypes";

function HoursFloat() {
  const { content }: { content: Content } = useGlobalContext();

  return (
    content && (
      <div className="bg-body bg-opacity-80 border border-body-color border-4 rounded-3 text-center text-nowrap">
        <div className="p-2 text-primary">
          <h2 className="fw-lighter">Sprechzeiten</h2>
          <table className="table table-borderless mb-1">
            <tbody>
              {content.hours.map((entry, index: number) => {
                const tableDataClasses = "bg-transparent text-primary p-1";
                return (
                  <tr key={index}>
                    <td className={tableDataClasses}>{entry.label}</td>
                    <td className={tableDataClasses}>{entry.value}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div className="d-flex flex-column">
            <Link
              to="/#contact"
              className="btn border border-body-color border-1 shadow text-primary"
            >
              {content.sections[4].title}
            </Link>
            <Link
              to="https://www.termin-patmed.de/p/dankwartstrasse/patient-type"
              target="_blank"
              rel="noopener noreferrer"
              className="btn btn-primary mt-2 shadow position-relative"
            >
              <b>Terminbuchung</b>
              <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                Neu
                <span className="visually-hidden">neues Feature: Online-Terminbuchung</span>
              </span>
            </Link>
          </div>
        </div>
      </div>
    )
  );
}

export default HoursFloat;
