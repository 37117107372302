import { HTMLReactParserOptions } from "html-react-parser";

import FoldedElements from "./FoldedElements";
import ImageLandscape from "./ImageLandscape";
import JobPosting from "./JobPosting";
import ParsedHTML from "./ParsedHTML";

import { SectionElement } from "../util/contentTypes";

interface Props {
  element: SectionElement;
  parserOptions?: HTMLReactParserOptions;
}

function ConstructedElement({ element, parserOptions }: Props) {
  if (!element) return <></>;

  switch (element.type) {
    case "textblock":
      return <ParsedHTML html={element.value!} parserOptions={parserOptions} />;

    case "imageLandscape":
      return (
        <ImageLandscape
          src={element.src}
          alt={element.alt}
          align={element.align}
        />
      );

    case "jobPosting":
      return (
        <JobPosting
          src_mobile={element.src_mobile}
          src_landscape={element.src_landscape}
          link={element.link}
          alt={element.alt}
        />
      );

    case "foldedElements":
      return (
        <FoldedElements
          elementsFolded={element.elementsFolded}
          elementsExpanded={element.elementsExpanded}
          parserOptions={parserOptions}
        />
      );

    default:
      return <></>;
  }
}

export default ConstructedElement;
