import { ReactNode } from "react";

import ImageParallax from "./ImageParallax";

interface Props {
  title?: string;
  fragment?: string;
  children?: ReactNode;
  parallaxImage?: string;
  fullWidth?: boolean;
}

function Section({ title, fragment, children, parallaxImage, fullWidth }: Props) {
  const bottomPadding = fullWidth ? "" : " pb-b-3";

  const style = {
    maxWidth: fullWidth ? "initial" : "1035px",
  };

  return (
    <section id={fragment} style={style} className={"w-100" + bottomPadding}>
      {title && (
        <h2 className="fw-lighter text-center m-0 pt-4 pb-0 px-p10 px-b-p15">
          {title}
        </h2>
      )}
      {children}
      {parallaxImage && <ImageParallax src={parallaxImage} />}
    </section>
  );
}

export default Section;
