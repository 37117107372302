import { useContext } from "react";

import { GlobalContext } from "../App";

import useIsLoggedIn from "./useIsLoggedIn";
import useWindowDimensions from "./useWindowDimensions";

export default function useGlobalContext() {
  const context = useContext(GlobalContext);
  const isLoggedIn: any = useIsLoggedIn();
  const windowDimensions = useWindowDimensions();
  return { ...context, isLoggedIn, ...windowDimensions };
}
