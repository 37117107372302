import { HTMLReactParserOptions } from "html-react-parser";

import ConstructedElement from "./ConstructedElement";

import { SectionElement } from "../util/contentTypes";

interface Props {
  elements?: SectionElement[];
  parserOptions?: HTMLReactParserOptions;
}

function ConstructedElementList({ elements, parserOptions }: Props) {
  return (
    <>
      {elements &&
        elements.map((element: SectionElement, index: number) => {
          return (
            <ConstructedElement
              key={index}
              element={element}
              parserOptions={parserOptions}
            />
          );
        })}
    </>
  );
}

export default ConstructedElementList;
