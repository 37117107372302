import { useRef, useState, useEffect } from "react";

import { useInViewport } from "react-in-viewport";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import { ClassicEditor } from "ckeditor5";

import "ckeditor5/ckeditor5.css";
import { editorConfig } from "../util/editorConfig";

interface Props {
  data: string;
  setData: (data: string) => void;
}

function RichTextEditor({ data, setData }: Props) {
  const [isLayoutReady, setIsLayoutReady] = useState(false);

  useEffect(() => {
    setIsLayoutReady(true);
    return () => setIsLayoutReady(false);
  }, []);

  const ref = useRef(null);
  const { inViewport } = useInViewport(ref, {}, { disconnectOnLeave: false });

  return (
    <div ref={ref}>
      {inViewport && isLayoutReady && (
        <CKEditor
          editor={ClassicEditor}
          data={data}
          config={editorConfig}
          onChange={(event, editor) => setData(editor.data.get())}
        />
      )}
    </div>
  );
}

export default RichTextEditor;
