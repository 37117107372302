import { useEffect, useState } from "react";

import Header from "./Header";
import HoursFloat from "./HoursFloat";
import NewsFloat from "./NewsFloat";

import useGlobalContext from "../hooks/useGlobalContext";
import { Content } from "../util/contentTypes";

function VideoHero() {
  const { content }: { content: Content } = useGlobalContext();
  const { height, width, isWidescreen } = useGlobalContext();

  const [showNews, setShowNews] = useState(false);
  const [showHours, setShowHours] = useState(true);
  const [activeNews, setActiveNews] = useState(0);
  const [anyNewsToShow, setAnyNewsToShow] = useState(false);

  useEffect(() => {
    if (content === undefined) return;

    content.news.map((news) => {
      if (news.duration && news.duration > 0) setAnyNewsToShow(true);
      return null;
    });
    if (!anyNewsToShow) return;

    function switchToNextNews() {
      setActiveNews(
        (prevActiveNews) => (prevActiveNews + 1) % content.news.length
      );
    }

    const currentNews = content.news[activeNews];
    let timeoutId: any;

    if (!isWidescreen || content.news.length === 0) {
      // for small screens (portrait mode) only show either hours or news
      if (showHours) {
        // if hours are active (as initially), disable news
        setShowNews(false);
        timeoutId = setTimeout(() => {
          // after timeout switch off hours (-> go to news)
          setShowHours(false);
        }, content.settings.hoursFrontPageCarouselDisplayTime * 1000);
      } else {
        // if hours are inactive (-> news), show news section
        setShowNews(true);
        timeoutId = setTimeout(() => {
          // after timeout switch to next news (keep hours inactive)
          switchToNextNews();
          if (activeNews === content.news.length - 1) {
            // after last news, reactivate hours
            setShowHours(true);
          }
        }, currentNews.duration * 1000);
      }
    } else {
      setShowNews(true);
      setShowHours(true);

      timeoutId = setTimeout(() => {
        switchToNextNews();
      }, currentNews.duration * 1000);
    }

    return () => clearTimeout(timeoutId);
  }, [activeNews, content, isWidescreen, showHours, anyNewsToShow]);

  let videoWidth: any;
  let videoHeight: any;
  let videoSrc: string;
  let videoPosterSrc: string;

  if (!isWidescreen) {
    videoWidth = Math.max(width, (height / 9) * 16);
    videoHeight = "auto";
    videoSrc = "media/video_mobile.mp4";
    videoPosterSrc = "media/video_mobile_thumbnail.jpg";
  } else {
    videoWidth = "auto";
    videoHeight = Math.max(height, (width / 16) * 9);
    videoSrc = "media/video.mp4";
    videoPosterSrc = "media/video_thumbnail.jpg";
  }

  const videoStyle = {
    width: videoWidth,
    height: videoHeight,
  };

  const floatAreaStyle = {
    paddingLeft: Math.max(0, (width - 1024) / 2),
    paddingRight: Math.max(0, (width - 1024) / 2),
  };

  return (
    <div
      className="position-relative w-100 overflow-hidden"
      style={{ height: height }}
    >
      <video
        key={videoSrc}
        muted
        autoPlay
        loop
        poster={videoPosterSrc}
        className="position-absolute top-50 start-50 translate-middle"
        style={videoStyle}
      >
        <source src={videoSrc} type="video/mp4" />
      </video>

      <div
        className="position-absolute w-100 d-flex flex-column justify-content-between"
        style={{ height: height }}
      >
        <Header />
        <div
          className="d-flex flex-row-reverse justify-content-between align-items-end m-3 m-md-5"
          style={floatAreaStyle}
        >
          {showHours && <HoursFloat />}
          {content && showNews && (
            <NewsFloat
              text={content.news[activeNews].shortDescription || ""}
              url={"/news#" + content.news[activeNews].title}
              duration={content.news[activeNews].duration}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default VideoHero;
