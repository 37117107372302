export const capitalize = (s: string) => s[0].toUpperCase() + s.substring(1);
export const plainText = (s: string) =>
  s
    .replace(/<br>+/g, " ")
    .replace("\n", " ")
    .replace(/<[^>]+>/g, "");
export const getNWords = (
  s: string,
  n: number,
  convertToPlainText: boolean = true
) => {
  if (convertToPlainText) s = plainText(s);
  const words = s.split(" ");
  const length = words.length;
  let output = words.slice(0, n).join(" ");
  if (n < length) output += "...";
  return output;
};
