import ConstructedElementList from "../components/ConstructedElementList";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Section from "../components/Section";

import useGlobalContext from "../hooks/useGlobalContext";
import { defaultSectionParserOptions } from "../components/ParserOptions";
import { Content } from "../util/contentTypes";

function Impressum() {
  const { content }: { content: Content } = useGlobalContext();

  return (
    <>
      <Header />
      <main className="d-flex flex-column align-items-center flex-fill">
        {content && (
          <Section fragment="impressum" title={content.sections[6].title}>
            {content && (
              <ConstructedElementList
                elements={content.sections[6].elements}
                parserOptions={defaultSectionParserOptions}
              />
            )}
          </Section>
        )}
      </main>
      <Footer />
    </>
  );
}

export default Impressum;
