import { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

import { HashLink as Link } from "react-router-hash-link";

import { hyphenateSync as hyphenate } from "hyphen/de";

import ParsedHTML from "./ParsedHTML";

import useGlobalContext from "../hooks/useGlobalContext";

interface Props {
  text: string;
  url: string;
  duration: number;
}

function NewsFloat({ text, url, duration }: Props) {
  const { isWidescreen } = useGlobalContext();
  const [progress, setProgress] = useState(0);

  const boxStyle = {
    maxWidth: !isWidescreen ? "230px" : "360px",
  };

  const progressStyle = {
    height: "4px",
    "--bs-progress-bar-transition": "width 0.1s ease",
  };

  useEffect(() => {
    const interval = 0.1; // in s
    setProgress(0);

    const timer = setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress + interval >= duration) {
          clearInterval(timer);
          return duration;
        }
        return prevProgress + interval;
      });
    }, interval * 1000);

    return () => {
      clearInterval(timer);
    };
  }, [text, url, duration]);

  return (
    <div
      className="bg-body bg-opacity-80 border border-body-color border-4 rounded-3"
      style={boxStyle}
    >
      <div className="p-2 text-primary">
        <h2 className="fw-lighter">Aktuelles</h2>

        <p className="mb-1">
          <ParsedHTML html={hyphenate(text)} />{" "}
          <Link to={url} className="text-nowrap text-primary">
            Mehr lesen...
          </Link>
        </p>

        <ProgressBar
          now={progress}
          min={0}
          max={duration}
          style={progressStyle}
          className="bg-transparent"
        />
      </div>
    </div>
  );
}

export default NewsFloat;
