import { useState } from "react";
import Modal from "react-bootstrap/Modal";

import ConstructedElementList from "../ConstructedElementList";
import ParsedHTML from "../ParsedHTML";
import Section from "../Section";
import ServiceCard from "../ServiceCard";

import { defaultSectionParserOptions } from "../ParserOptions";
import useGlobalContext from "../../hooks/useGlobalContext";
import { Content } from "../../util/contentTypes";

function SectionServices() {
  const { content, isWidescreen }: {content: Content; isWidescreen: boolean} = useGlobalContext();
  const [activeService, setActiveService] = useState(-1);

  const handleClose = () => setActiveService(-1);

  return (
    content && (
      <Section
        fragment="services"
        title={content.sections[1].title}
        parallaxImage={content.sections[1].parallaxImage}
      >
        <ConstructedElementList
          elements={[content.sections[1].elements![0]]}
          parserOptions={defaultSectionParserOptions}
        />

        <div
          className={
            "row row-cols-2 row-cols-sm-3 row-cols-xl-4 g-3 g-sm-4 m-1"
          }
        >
          {content.services.map((service, index: number) => {
            return (
              <div className="col" key={index}>
                <ServiceCard
                  imgSrc={
                    !isWidescreen ? service.imgSrcSmall : service.imgSrcLarge
                  }
                  title={service.shortTitle}
                  subtitle={service.shortDescription}
                  onClick={() => {
                    setActiveService(index);
                  }}
                />
              </div>
            );
          })}
        </div>

        <ConstructedElementList
          elements={[content.sections[1].elements![2]]}
          parserOptions={defaultSectionParserOptions}
        />

        {activeService >= 0 && (
          <Modal show={activeService >= 0} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>
                <ParsedHTML
                  html={content.services[activeService].title}
                />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <ConstructedElementList
                elements={content.services[activeService].description}
              />
            </Modal.Body>
          </Modal>
        )}
      </Section>
    )
  );
}

export default SectionServices;
