import { HashLink as Link } from "react-router-hash-link";

import {
  HTMLReactParserOptions,
  attributesToProps,
  domToReact,
} from "html-react-parser";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  faPhoneFlip,
  faEnvelope,
  faFax,
} from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

export const defaultSectionParserOptions: HTMLReactParserOptions = {
  replace(domNode: any) {
    if (!domNode) return <></>;

    const props = attributesToProps(domNode.attribs);
    const existingClasses = props.className;
    const existingStyle = props.style;

    switch (domNode.name) {
      case "p": {
        const additionalClasses = "m-0 py-2 px-p10 px-b-p15";
        const updatedClasses = [existingClasses, additionalClasses]
          .filter((s) => s)
          .join(" ");
        return (
          <p className={updatedClasses}>
            {domToReact(domNode.children, defaultSectionParserOptions)}
          </p>
        );
      }
      case "h4":
        return (
          <h4 id={props.id as string} className="m-0 py-2 px-p10 px-b-p15">
            {domToReact(domNode.children, defaultSectionParserOptions)}
          </h4>
        );
      case "ul":
        return (
          <div className="px-p10 px-b-p15">
            <ul>{domToReact(domNode.children, defaultSectionParserOptions)}</ul>
          </div>
        );
      case "a": {
        const additionalClasses = "text-reset text-decoration-none";
        const updatedClasses = [existingClasses, additionalClasses]
          .filter((s) => s)
          .join(" ");
        const href = (props.href as string).toLowerCase();
        return (
          <Link to={href} className={updatedClasses} style={existingStyle}>
            {domToReact(domNode.children, defaultSectionParserOptions)}
          </Link>
        );
      }
      case "i":
        switch (existingClasses) {
          case "phone":
            return <FontAwesomeIcon icon={faPhoneFlip} />;
          case "envelope":
            return <FontAwesomeIcon icon={faEnvelope} />;
          case "fax":
            return <FontAwesomeIcon icon={faFax} />;
          case "google":
            return <FontAwesomeIcon icon={faGoogle} />;
          case "copyright":
            return <FontAwesomeIcon icon={faCopyright} />;
          default:
            return domNode;
        }
      default:
        return domNode;
    }
  },
};
