import { ReactNode, useState } from "react";
import { Button, Card } from "react-bootstrap";

import useGlobalContext from "../hooks/useGlobalContext";

interface Props {
  modal?: boolean;
  description: string;
  accept: string;
  reject?: string;
  children?: ReactNode;
}

function CookieNotice({ modal, description, accept, reject, children }: Props) {
  const { cookiesAccepted, handleAcceptCookies, isWidescreen } =
    useGlobalContext();
  const [rejected, setRejected] = useState(false);

  if (children ? modal : !modal) {
    throw Error(
      "either modal has to be true or child nodes have to be present"
    );
  }

  if (cookiesAccepted) {
    return <>{children}</>;
  }
  // else: cookies are not yet accepted

  if (modal && rejected) {
    return <></>;
  }

  function handleAccept() {
    handleAcceptCookies(true);
  }

  let divClasses = "overflow-hidden bg-dark bg-opacity-80";
  divClasses += modal
    ? " position-fixed vw-100 vh-100"
    : " w-100 d-flex justify-content-center";

  let cardClasses = "text-primary border border-primary border-3";
  cardClasses += isWidescreen ? " w-50" : " rounded-0 w-100";
  cardClasses += isWidescreen && modal ? " mb-3 me-p10" : "";
  cardClasses += !modal ? " my-3" : "";
  cardClasses += modal ? " position-fixed bottom-0 end-0" : "";

  return (
    <div className={divClasses}>
      <Card className={cardClasses}>
        <Card.Body>{description}</Card.Body>
        <Card.Footer className="d-flex justify-content-end">
          <Button variant="primary" onClick={handleAccept}>
            {accept}
          </Button>
          {reject && (
            <Button
              variant="secondary"
              className="ms-3"
              onClick={() => setRejected(true)}
            >
              {reject}
            </Button>
          )}
        </Card.Footer>
      </Card>
    </div>
  );
}

export default CookieNotice;
