import Footer from "../components/Footer";
import SectionAbout from "../components/sections/SectionAbout";
import SectionContact from "../components/sections/SectionContact";
import SectionDownloads from "../components/sections/SectionDownloads";
import SectionHours from "../components/sections/SectionHours";
import SectionMap from "../components/sections/SectionMap";
import SectionServices from "../components/sections/SectionServices";
import SectionTeam from "../components/sections/SectionTeam";
import VideoHero from "../components/VideoHero";

function Home() {
  return (
    <>
      <VideoHero />
      <main className="d-flex flex-column align-items-center flex-fill">
        <SectionAbout />
        <SectionServices />
        <SectionTeam />
        <SectionHours />
        <SectionContact />
        <SectionDownloads />
        <SectionMap />
      </main>
      <Footer />
    </>
  );
}

export default Home;
