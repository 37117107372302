import { MouseEvent, useState } from "react";
import { HTMLReactParserOptions } from "html-react-parser";

import ConstructedElementList from "./ConstructedElementList";

import useGlobalContext from "../hooks/useGlobalContext";

interface Props {
  elementsFolded: any[];
  elementsExpanded: any[];
  parserOptions?: HTMLReactParserOptions;
}

function FoldedElements({
  elementsFolded,
  elementsExpanded,
  parserOptions,
}: Props) {
  const { isWidescreen } = useGlobalContext();
  const [folded, setFolded] = useState(true);
  const handleUnfold = (event: MouseEvent) => {
    event.preventDefault();
    setFolded(false);
  };
  return !isWidescreen && folded ? (
    <div onClick={handleUnfold}>
      <ConstructedElementList
        elements={elementsFolded}
        parserOptions={parserOptions}
      />
    </div>
  ) : (
    <ConstructedElementList
      elements={elementsExpanded}
      parserOptions={parserOptions}
    />
  );
}

export default FoldedElements;
