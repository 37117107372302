import { Container, Nav, Navbar } from "react-bootstrap";
import { Link } from "react-router-dom";

import { produce } from "immer";

import AccordionListEditor from "../components/AccordionListEditor";
import CreateUser from "../components/CreateUser";
import Editor from "../components/Editor";
import Footer from "../components/Footer";
import Section from "../components/Section";

import useGlobalContext from "../hooks/useGlobalContext";
import { Content } from "../util/contentTypes";

type ContentKey =
  | "sections"
  | "news"
  | "services"
  | "team"
  | "hours"
  | "downloads";

function Edit() {
  const {
    content,
    setContent,
  }: { content: Content; setContent: (content: Content) => void } =
    useGlobalContext();

  function updateContentElement(key: ContentKey, newElement: any) {
    setContent(
      produce(content, (draft) => {
        draft[key] = newElement;
      })
    );
  }

  const lists: [ContentKey, string][] = [
    ["sections", "Abschnitte"],
    ["news", "News"],
    ["services", "Leistungen"],
    ["team", "Team"],
    ["hours", "Sprechzeiten"],
    ["downloads", "Downloads"],
  ];

  return (
    <>
      <div className="overflow-hidden vh-100 d-flex flex-column">
        <Navbar expand="lg" className="bg-body">
          <Container>
            <Navbar.Brand as={Link} to="/#">
              Startseite
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="me-auto">
                <Nav.Link href="#sections">Abschnitte</Nav.Link>
                <Nav.Link href="#news">News</Nav.Link>
                <Nav.Link href="#services">Leistungen</Nav.Link>
                <Nav.Link href="#team">Team</Nav.Link>
                <Nav.Link href="#hours">Sprechzeiten</Nav.Link>
                <Nav.Link href="#downloads">Downloads</Nav.Link>
                <Nav.Link href="#settings">Einstellungen</Nav.Link>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>

        <div className="overflow-auto">
          <main className="d-flex flex-column align-items-center flex-fill">
            {content && (
              <>
                {lists.map(([key, title]) => {
                  return (
                    <Section key={key} fragment={key} title={title}>
                      <AccordionListEditor
                        list={content[key]}
                        setList={(newList) =>
                          updateContentElement(key, newList)
                        }
                      />
                    </Section>
                  );
                })}
                <Section fragment="settings" title="Einstellungen">
                  <Editor
                    data={content.settings}
                    setData={(data: any) =>
                      setContent(
                        produce(content, (draft: any) => {
                          // NOTE: any necessary, otherwise key type is not found
                          draft["settings"] = data;
                        })
                      )
                    }
                  />
                </Section>
                <Section fragment="new_user" title="Erstelle Nutzer">
                  <CreateUser />
                </Section>
              </>
            )}
          </main>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Edit;
