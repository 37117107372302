import { useRef, useState } from "react";

import { useInViewport } from "react-in-viewport";

import Carousel from "react-bootstrap/Carousel";
import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import ConstructedElementList from "./ConstructedElementList";

import { Person } from "../util/contentTypes";

interface Props {
  persons: Person[];
}

function TeamCarousel({ persons }: Props) {
  const [carouselIndex, setCarouselIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);

  const ref = useRef(null);
  const { inViewport } = useInViewport(ref, {}, { disconnectOnLeave: false });

  function handleCarouselSelect(index: number) {
    if (inViewport && !showModal) {
      setCarouselIndex(index);
    }
  }

  const shadowStyle = {
    textShadow:
      "0 0 20px black, 0 0 20px black, 0 0 20px black, 0 0 20px black, 0 0 20px black, 0 0 20px black",
  };

  return (
    <div ref={ref}>
      <Carousel
        activeIndex={carouselIndex}
        onSelect={handleCarouselSelect}
        prevIcon={
          <FontAwesomeIcon icon={faChevronLeft} color="black" size="2x" />
        }
        nextIcon={
          <FontAwesomeIcon icon={faChevronRight} color="black" size="2x" />
        }
      >
        {persons.map((person, index) => (
          <Carousel.Item
            key={index}
            onClick={() => person.detailsEnabled && setShowModal(true)}
            interval={person.duration * 1000}
            style={person.detailsEnabled ? { cursor: "pointer" } : {}}
          >
            <div className="position-relative" style={{ height: "450px" }}>
              <img
                className="img-fluid img-thumbnail d-block mx-auto rounded rounded-4 border-0 position-absolute top-50 start-50 translate-middle"
                style={{ maxHeight: "450px" }}
                src={person.src}
                alt={person.name}
              />
            </div>
            <Carousel.Caption>
              {person.name && <h3 style={shadowStyle}>{person.name}</h3>}
              {person.shortDescription && (
                <p style={shadowStyle}>{person.shortDescription}</p>
              )}
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>

      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{persons[carouselIndex].name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConstructedElementList
            elements={persons[carouselIndex].description}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default TeamCarousel;
