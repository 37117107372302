import ConstructedElementList from "../ConstructedElementList";
import Section from "../Section";

import { defaultSectionParserOptions } from "../ParserOptions";
import useGlobalContext from "../../hooks/useGlobalContext";
import { Content } from "../../util/contentTypes";

function SectionContact() {
  const { content }: { content: Content } = useGlobalContext();

  return (
    content && (
      <Section
        fragment="contact"
        title={content.sections[4].title}
        parallaxImage={content.sections[4].parallaxImage}
      >
        <div>
          <ConstructedElementList
            elements={content.sections[4].elements}
            parserOptions={defaultSectionParserOptions}
          />
        </div>
      </Section>
    )
  );
}

export default SectionContact;
