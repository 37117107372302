import Section from "../Section";

import useGlobalContext from "../../hooks/useGlobalContext";
import CookieNotice from "../CookieNotice";

import { Content } from "../../util/contentTypes";

function SectionMap() {
  const { content }: { content: Content } = useGlobalContext();

  const style = {
    width: "100%",
    height: "400px",
    transform: "translateY(6px)",
  };

  return (
    content && (
      <Section fragment="map" title={content.sections[5].title} fullWidth>
        <CookieNotice
          description="Um die Karte anzuzeigen, müssen Sie Inhalte von Drittanbietern (Google) erlauben."
          accept="Akzeptieren und Karte anzeigen"
        >
          <div>
            <iframe
              title="map"
              src={content.settings.mapEmbedSrc}
              className="pt-2"
              style={style}
              loading="lazy"
            ></iframe>
          </div>
        </CookieNotice>
      </Section>
    )
  );
}

export default SectionMap;
