import ConstructedElementList from "../ConstructedElementList";
import Section from "../Section";

import { defaultSectionParserOptions } from "../ParserOptions";
import { News } from "../../util/contentTypes";

function SectionNews({ title, description }: News) {
  return (
    <Section fragment={title} title={title}>
      <ConstructedElementList
        elements={description}
        parserOptions={defaultSectionParserOptions}
      />
    </Section>
  );
}

export default SectionNews;
