import { useNavigate } from "react-router-dom";

import { HashLink as Link } from "react-router-hash-link";

import { signOut } from "supertokens-auth-react/recipe/emailpassword";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { faPhoneFlip, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";

import useGlobalContext from "../hooks/useGlobalContext";

function Footer() {
  const { isLoggedIn } = useGlobalContext();
  const navigate = useNavigate();

  return (
    <>
      <footer className="d-flex justify-content-center bg-dark">
        <div className="d-flex flex-column">
          <div className="d-flex justify-content-center flex-wrap">
            <Link to="/#contact" className="btn text-light">
              Kontakt
            </Link>
            <Link to="/impressum#" className="btn text-light">
              Impressum
            </Link>
            <Link to="/edit#" className="btn text-light">
              Editor
            </Link>
            {isLoggedIn && (
              <button
                onClick={async () => {
                  await signOut();
                  navigate("/#");
                }}
                className="btn text-light"
              >
                Logout
              </button>
            )}
          </div>
          <div className="d-flex justify-content-center">
            <Link className="btn text-light" to="tel:+492233974950">
              <FontAwesomeIcon icon={faPhoneFlip} />
            </Link>
            <Link
              className="btn text-light"
              to="mailto:info@praxis-dankwartstr.com"
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Link>
            <Link
              className="btn text-light"
              to="https://goo.gl/maps/yNMgjTa91SSJ4hoK8"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faGoogle} />
            </Link>
          </div>
          <div className="d-flex justify-content-center">
            <Link to="/#" className="btn text-light">
              <FontAwesomeIcon icon={faCopyright} /> Praxis Dankwartstraße{" "}
              {new Date().getFullYear()}
            </Link>
          </div>
        </div>
      </footer>
    </>
  );
}

export default Footer;
