import ConstructedElementList from "../ConstructedElementList";
import Section from "../Section";

import { defaultSectionParserOptions } from "../ParserOptions";
import useGlobalContext from "../../hooks/useGlobalContext";
import { Content } from "../../util/contentTypes";

function SectionAbout() {
  const { content }: {content: Content} = useGlobalContext();

  return (
    content && (
      <Section
        fragment="about"
        title={content.sections[0].title}
        parallaxImage={content.sections[0].parallaxImage}
      >
        <ConstructedElementList
          elements={content.sections[0].elements}
          parserOptions={defaultSectionParserOptions}
        />
      </Section>
    )
  );
}

export default SectionAbout;
