import { useState } from "react";
import { Button } from "react-bootstrap";

import DownloadsCard from "../DownloadsCard";
import Section from "../Section";

import useGlobalContext from "../../hooks/useGlobalContext";
import { Content } from "../../util/contentTypes";

function SectionDownloads() {
  const { content }: {content: Content} = useGlobalContext();
  const [showDownloads, setShowDownloads] = useState(false);

  return (
    content &&
    (showDownloads ? (
      <Section fragment="downloads" title="Downloads" fullWidth>
        <div className="mt-3 mx-p10 mx-b-p15 d-flex flex-column align-items-center">
          {content.downloads.map((download, index) => (
            <DownloadsCard
              key={index}
              title={download.title}
              description={download.description}
              src={download.src}
            />
          ))}
        </div>
      </Section>
    ) : (
      <Section fragment="downloads">
        <div className="p-2 text-center">
          <Button variant="primary" onClick={() => setShowDownloads(true)}>
            Downloads Anzeigen
          </Button>
        </div>
      </Section>
    ))
  );
}

export default SectionDownloads;
