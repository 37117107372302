import ConstructedElementList from "../ConstructedElementList";
import Section from "../Section";

import { defaultSectionParserOptions } from "../ParserOptions";
import useGlobalContext from "../../hooks/useGlobalContext";
import { Content } from "../../util/contentTypes";

function SectionHours() {
  const { content }: {content: Content} = useGlobalContext();

  return (
    content && (
      <Section
        fragment="hours"
        title={content.sections[3].title}
        parallaxImage={content.sections[3].parallaxImage}
      >
        <div className="d-flex justify-content-center p-3">
          <div className="border border-2 rounded-4 border-secondary p-2">
            <table
              className="table table-borderless mb-0"
              style={{ maxWidth: "360px" }}
            >
              <tbody>
                {content.hours.map((entry, index: number) => {
                  const tableDataClasses =
                    "bg-transparent text-body p-1 text-center align-middle";
                  return (
                    <tr key={index}>
                      <td className={tableDataClasses}>{entry.labelLong}</td>
                      <td className={tableDataClasses}>{entry.valueLong}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
        <ConstructedElementList
          elements={content.sections[3].elements}
          parserOptions={defaultSectionParserOptions}
        />
      </Section>
    )
  );
}

export default SectionHours;
