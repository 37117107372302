import ConstructedElementList from "../ConstructedElementList";
import Section from "../Section";
import TeamCarousel from "../TeamCarousel";

import { defaultSectionParserOptions } from "../ParserOptions";
import useGlobalContext from "../../hooks/useGlobalContext";
import { Content } from "../../util/contentTypes";

function SectionTeam() {
  const { content }: { content: Content } = useGlobalContext();

  return (
    content && (
      <Section
        fragment="team"
        title={content.sections[2].title}
        parallaxImage={content.sections[2].parallaxImage}
      >
        <ConstructedElementList
          elements={content.sections[2].elements}
          parserOptions={defaultSectionParserOptions}
        />

        <div className="px-p10 px-b-p15 py-3">
          <TeamCarousel persons={content.team} />
        </div>
      </Section>
    )
  );
}

export default SectionTeam;
