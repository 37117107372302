import { FloatingLabel, Form } from "react-bootstrap";

import { produce } from "immer";

import AccordionListEditor from "./AccordionListEditor";
import RichTextEditor from "./RichTextEditor";

import {
  Download,
  HoursEntry,
  News,
  Person,
  Section,
  SectionElement,
  Service,
  Settings,
} from "../util/contentTypes";
import { capitalize } from "../util/textTools";

interface Props {
  data:
    | Section
    | News
    | Service
    | Person
    | HoursEntry
    | Download
    | Settings
    | SectionElement;
  setData: (
    data:
      | Section
      | News
      | Service
      | Person
      | HoursEntry
      | Download
      | Settings
      | SectionElement
  ) => void;
}

const selectableTypes = [
  "textblock",
  "imageLandscape",
  "foldedElements",
  "services",
  "team",
  "hours",
  "downloads",
  "map",
  "jobPosting",
];

function Editor({ data, setData }: Props) {
  if ("type" in data) {
    return (
      <>
        <FloatingLabel label="Type">
          <Form.Select
            value={data.type}
            onChange={(event) =>
              setData(
                produce(data, (draft: any) => {
                  // NOTE: any necessary, otherwise key type is not found
                  switch (draft["type"]) {
                    case "textblock":
                      delete draft["value"];
                      break;
                    case "imageLandscape":
                      delete draft["src"];
                      delete draft["alt"];
                      delete draft["align"];
                      break;
                    case "foldedElements":
                      delete draft["elementsFolded"];
                      delete draft["elementsExpanded"];
                      break;
                    case "jobPosting":
                      delete draft["src_mobile"];
                      delete draft["src_landscape"];
                      delete draft["news_link"];
                      delete draft["alt"];
                      break;
                  }
                  draft["type"] = event.target.value;
                  switch (event.target.value) {
                    case "textblock":
                      draft["value"] = "";
                      break;
                    case "imageLandscape":
                      draft["src"] = "";
                      draft["alt"] = "";
                      draft["align"] = "";
                      break;
                    case "foldedElements":
                      draft["elementsFolded"] = [
                        {
                          type: "textblock",
                          value: "",
                        },
                      ];
                      draft["elementsExpanded"] = [
                        {
                          type: "textblock",
                          value: "",
                        },
                      ];
                      break;
                    case "jobPosting":
                      draft["src_mobile"] = "";
                      draft["src_landscape"] = "";
                      draft["news_link"] = "news#";
                      draft["alt"] = "";
                      break;
                  }
                })
              )
            }
          >
            {selectableTypes.map((type) => (
              <option key={type} value={type}>
                {capitalize(type)}
              </option>
            ))}
          </Form.Select>
        </FloatingLabel>
        {data.type === "textblock" && (
          <div className="mt-3 p-3">
            <RichTextEditor
              data={data.value!}
              setData={(value) =>
                setData(
                  produce(data, (draft) => {
                    draft["value"] = value;
                  })
                )
              }
            />
          </div>
        )}
        {data.type === "imageLandscape" && (
          <>
            <FloatingLabel label="src" className="mt-3">
              <Form.Control
                placeholder=""
                value={data.src}
                onChange={(event) =>
                  setData(
                    produce(data, (draft: any) => {
                      // NOTE: any necessary, otherwise key type is not found
                      draft["src"] = event.target.value;
                    })
                  )
                }
              />
            </FloatingLabel>
            <FloatingLabel label="alt" className="mt-3">
              <Form.Control
                placeholder=""
                value={data.alt}
                onChange={(event) =>
                  setData(
                    produce(data, (draft: any) => {
                      // NOTE: any necessary, otherwise key type is not found
                      draft["alt"] = event.target.value;
                    })
                  )
                }
              />
            </FloatingLabel>
            <FloatingLabel label="align" className="mt-3">
              <Form.Control
                placeholder=""
                value={data.align}
                onChange={(event) =>
                  setData(
                    produce(data, (draft: any) => {
                      // NOTE: any necessary, otherwise key type is not found
                      draft["align"] = event.target.value;
                    })
                  )
                }
              />
            </FloatingLabel>
          </>
        )}
        {data.type === "jobPosting" && (
          <>
            <FloatingLabel label="src_mobile" className="mt-3">
              <Form.Control
                placeholder=""
                value={data.src_mobile}
                onChange={(event) =>
                  setData(
                    produce(data, (draft: any) => {
                      // NOTE: any necessary, otherwise key type is not found
                      draft["src_mobile"] = event.target.value;
                    })
                  )
                }
              />
            </FloatingLabel>
            <FloatingLabel label="src_landscape" className="mt-3">
              <Form.Control
                placeholder=""
                value={data.src_landscape}
                onChange={(event) =>
                  setData(
                    produce(data, (draft: any) => {
                      // NOTE: any necessary, otherwise key type is not found
                      draft["src_landscape"] = event.target.value;
                    })
                  )
                }
              />
            </FloatingLabel>
            <FloatingLabel label="link" className="mt-3">
              <Form.Control
                placeholder=""
                value={data.link}
                onChange={(event) =>
                  setData(
                    produce(data, (draft: any) => {
                      // NOTE: any necessary, otherwise key type is not found
                      draft["link"] = event.target.value;
                    })
                  )
                }
              />
            </FloatingLabel>
            <FloatingLabel label="alt" className="mt-3">
              <Form.Control
                placeholder=""
                value={data.alt}
                onChange={(event) =>
                  setData(
                    produce(data, (draft: any) => {
                      // NOTE: any necessary, otherwise key type is not found
                      draft["alt"] = event.target.value;
                    })
                  )
                }
              />
            </FloatingLabel>
          </>
        )}
        {data.type === "foldedElements" && (
          <>
            <h4 className="mt-3">Elements Folded</h4>
            <AccordionListEditor
              list={data.elementsFolded}
              setList={(newValue) =>
                setData(
                  produce(data, (draft: any) => {
                    draft["elementsFolded"] = newValue;
                  })
                )
              }
            />
            <h4 className="mt-3">Elements Expanded</h4>
            <AccordionListEditor
              list={data.elementsExpanded}
              setList={(newValue) =>
                setData(
                  produce(data, (draft: any) => {
                    draft["elementsExpanded"] = newValue;
                  })
                )
              }
            />
          </>
        )}
      </>
    );
  }
  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        if (Array.isArray(value))
          return (
            <div key={key} className="mb-3">
              <h4>{capitalize(key)}</h4>
              <AccordionListEditor
                list={value}
                setList={(newValue) =>
                  setData(
                    produce(data, (draft: any) => {
                      draft[key] = newValue;
                    })
                  )
                }
              />
            </div>
          );
        return (
          <FloatingLabel key={key} label={capitalize(key)} className="mb-3">
            <Form.Control
              placeholder=""
              value={value}
              onChange={(event) =>
                setData(
                  produce(data, (draft: any) => {
                    // NOTE: any necessary, otherwise key type is not found
                    draft[key] = event.target.value;
                  })
                )
              }
            />
          </FloatingLabel>
        );
      })}
    </>
  );
}

export default Editor;
