import { HashLink as Link } from "react-router-hash-link";

import useGlobalContext from "../hooks/useGlobalContext";

function Header() {
  const { isWidescreen } = useGlobalContext();

  let logoSize;
  if (!isWidescreen) {
    logoSize = "90px";
  } else {
    logoSize = "150px";
  }

  return (
    <Link to="/#" className="text-decoration-none">
      <header className="py-3 d-flex align-items-center justify-content-center flex-wrap bg-body bg-opacity-80">
        <img
          src="media/logo.png"
          alt="Logo"
          style={{ width: logoSize, height: logoSize }}
        />
        <div className="ms-4 text-center text-primary">
          <h1 className="m-0">
            <b>
              Praxis
              <br />
              Dankwartstraße
            </b>
          </h1>
          {isWidescreen && (
            <h2 className="mb-0 mt-4 fw-lighter">
              Innere Medizin - Allgemeinmedizin
            </h2>
          )}
        </div>
      </header>
    </Link>
  );
}

export default Header;
